import React, { Component } from 'react';


import axios from 'axios';
import e from 'cors';


export default class Contact extends Component {

state={
    name:'',
    lastname:'',
    email:'',
    message:'',
    sent:false
}

// handle inputs
handleName=(e)=>{
    this.setState({
        name:e.target.value
    })

}

handleLastName=(e)=>{
    this.setState({
        lastname:e.target.value
    })

}

handleEmail=(e)=>{
    this.setState({
        email:e.target.value
    })

}

handleMessage=(e)=>{
    this.setState({
        message:e.target.value
    })

}
// end of handle inputs


formSubmit=(e)=>{
    e.preventDefault();

    let data={
        name:this.state.name,
        lastname:this.state.lastname,
        email:this.state.email,
        message:this.state.message,
    }

    axios.post('/api/format',data)
    .then(res=>{
        this.setState({
            sent:true,
            },this.resetForm())

    }).catch(()=>{
        console.log('message not sent');
    })
}


// for reseting initial data
resetForm=()=>{
    this.setState({
        name:'',
        lastname:'',
        email:'',
        message:''
    })
    setTimeout(()=>{
        this.setState({
            sent:false,
        })
    },8080)
}

    render() {
        return (
            <div class="top">
                <h1>Contact Me!!!</h1>
            <div className='container'>
                <form onSubmit={this.formSubmit}>
                    {/* single item */}
                    <div className='singleItem'>
                        <label htmlFor='name'>Name</label>
                        <input type='text'
                        name='name'
                        className='name'
                        placeholder='your name...'
                        value={this.state.name}
                        onChange={this.handleName}
                        />
                    </div>
                    {/* end of single item */}


                    {/* single item */}
                    <div className='singleItemsingleItem'>
                        <label htmlFor='lastname'>Lastname</label>
                        <input type='text'
                        name='name'
                        className='lastname'
                        placeholder='your last name...'
                        value={this.state.lastname}
                        onChange={this.handleLastName}
                        required
                        />
                    </div>
                    {/* end of single item */}


                    {/* single item */}
                    <div className='singleItemsingleItemsingleItemsingleItem'>
                        <label htmlFor='email'>Email</label>
                        <input type='text'
                        name='email'
                        className='email'
                        placeholder='your email...'
                        value={this.state.email}
                        onChange={this.handleEmail}
                        />
                    </div>
                    {/* end of single item */}


                    {/* single item */}
                    <div className='textArea singleItemsingleItemsingleItem'>
                        <label htmlFor='message'>Message</label>
                        <textarea name='message'
                        id=''
                        cols='30'
                        rows='5'
                        placeholder='your message...'
                        value={this.state.message}
                        onChange={this.handleMessage}

                        ></textarea>
                    </div>
                    {/* end of single item */}
                    <div className={this.state.sent ?'msg msgAppear':'msg'}>Message has been sent</div>
                    <div className='btn'>
                        <button type='submit' >Submit</button>
                    </div>
                </form>
            </div>
            <div class="Linked-In">
            <a href='https://www.linkedin.com/in/roman-badiere/' target='_blank' rel='noopener noreferrer'>Let's connect on Linked-In!!!</a>
          </div>
            </div>
        )
    }
}
