import Me from './Me.jpg'
// import '.././App.css';
import { useEffect } from 'react';
import TagCloud from 'TagCloud';

const stick = () =>{
  let header = document.querySelector('header');
  header.classList.toggle('sticky', window.scrollY > 0)

}
function Home() {
  useEffect(() =>{
    window.addEventListener('scroll', stick);
    return ()=>{
      window.removeEventListener('scroll',stick)
    }
  }
  )
  useEffect(() => {
    return () => {
      const container = ".Home";
      const texts = [
        "React",
        "FastAPI",
        "Django",
        "Rust",
        "JavaScript",
        "Python",
        "Bevy",
        "Git",
        "Docker",
        "HTML5",
        "SQL",
        "CSS",
        "JWT",
        "Unit-Testing"
      ];

      const options = {
        radius: 350,
        maxSpeed: "normal",
        initSpeed: "normal",
        keep: true,
      };

      TagCloud(container, texts, options);
    };
  }, []);

  return (
    <div className="App">
        <div className="Home">
        <h1>Hello, I'm Roman!</h1>
        <span className="tagcloud" style={{marginLeft: '900px', marginTop: '-500px'}}>
        </span>
        </div>
    </div>
  );
}

export default Home;
