import GameNight from './GameNight.PNG';
import CarCar from './CarCar.PNG';
import BevyGame from './BevyGame.PNG';
import fastAPI from './fastAPI.png';
import React from './React.webp';
import JWT from './JWT.png';
import Django from './Django.png';
import postgrSQL from './postgrSQL.png'
import Docker from './Docker.png';
import Bevy from './Bevy.png';
import Rust from './Rust.png';
import git from './git.png';

function Portfolio(){

    return(
        <div class="portfolio-container">
            {/* <h1>Featured Projects</h1> */}

            {/* beggining of individual project right */}

            <div class="project">
                <div class="content-left">
                    <div class="title">
                    <h1>Game-Night</h1>
                    </div>
                    <div class="tech">
                    <h4>Technologies/Frameworks</h4>
                    </div>
                    <div class="technology-logos-container">
                        {/* this is where technology images would go */}
                    <div class='technology-logo'>
                    <img alt="fastAPI" src={fastAPI}></img>
                    <h4>fastAPI</h4>
                    </div>
                    <div class='technology-logo'>
                    <img alt="postgrSQL" src={postgrSQL}></img>
                    <h4>PostgreSQL</h4>
                    </div>
                    <div class='technology-logo'>
                    <img alt="JWT" src={JWT}></img>
                    <h4>JWT</h4>
                    </div>
                    </div>
                    <div class="description">
                    <p>Here you can schedule meetups with your friends for a boardgame night.</p>
                    </div>
                    <a class="project-live" target="_blank" href="https://gamenight-gang.gitlab.io/game-night/" rel='noopener noreferrer'>Live!</a>
                    <a class="project-repo" target="_blank" href="https://gitlab.com/gamenight-gang/game-night" rel='noopener noreferrer'>Repo</a>
                </div>
                <div class="image-right">
                    <img src={GameNight} alt="gamenight"></img>
                </div>
            </div>

            {/* end of individual project right */}


            {/* start of individual project left */}

            <div class="project">
                <div class="image-left">
                    <img src={CarCar} alt="CarCar"></img>
                </div>
                <div class="content-right">
                    <div class="title">
                    <h3>CarGo</h3>
                    </div>
                    <div class="tech">
                    <h4>Technologies/Frameworks</h4>
                    </div>
                    <div class="technology-logos-container">
                        {/* this is where technology images would go */}
                    <div class='technology-logo'>
                    <img alt="Django" src={Django}></img>
                    <h4>Django</h4>
                    </div>
                    <div class='technology-logo'>
                    <img alt="React" src={React}></img>
                    <h4>React</h4>
                    </div>
                    <div class='technology-logo'>
                    <img alt="Docker" src={Docker}></img>
                    <h4>Docker</h4>
                    </div>
                    </div>
                    <div class="description">
                    <p>A simple application for a car dealership. You can manage your service and sales operations. Additionally you can keep track of your vehicle inventory</p>
                    </div>
                    {/* <a class="project-live" target="_blank" href="https://gamenight-gang.gitlab.io/game-night/">Live!</a> */}
                    <a class="project-repo" target="_blank" href="https://gitlab.com/Byrne123/project-beta" rel='noopener noreferrer'>Repo</a>
                </div>
            </div>

            {/* end of individual project left */}

            {/* beggining of individual project right */}

            <div class="project">
                <div class="content-left">
                    <div class="title">
                    <h1>Dungeon-platformer</h1>
                    </div>
                    <div class="tech">
                    <h4>Technologies/Frameworks</h4>
                    </div>
                    <div class="technology-logos-container">
                        {/* this is where technology images would go */}
                    <div class='technology-logo'>
                    <img alt="Bevy" src={Bevy}></img>
                    <h4>Bevy</h4>
                    </div>
                    <div class='technology-logo'>
                    <img alt="Rust" src={Rust}></img>
                    <h4>Rust</h4>
                    </div>
                    <div class='technology-logo'>
                    <img alt="git" src={git}></img>
                    <h4>git</h4>
                    </div>
                    </div>
                    <div class="description">
                    <p>This is a platformer that has adjusting animations according to user inputs</p>
                    </div>
                    <a class="project-repo" target="_blank" href="https://github.com/Romulus162/Dungeon-Madness" rel='noopener noreferrer'>Repo</a>
                </div>
                <div class="image-right">
                    <img src={BevyGame} alt="BevyGame"></img>
                </div>
            </div>

            {/* end of individual project right */}
        </div>
    )
}

export default Portfolio
