import Me from './Me.jpg';
function About(){

    return (
        <div className='About'>
          <div class="intro">
          <div class="Me">
            <img src={Me} alt='Me'></img>
          </div>
          <h2>I am a FullStack Developer, with a strong passion for information attainment. Please enjoy your stay at my website!</h2>
          </div>
          <div class="Education">
            <h4>In Recent history, August 11th, 2023 to be exact I completed and earned my certificate of of completion from Hack Reactor. This was a very momentous occasion for myself as I had gained not only the knowledge that comes with such a course, but also the confidence that I can handle and overcome any problem thrown at me.
            </h4>
            <div class="Interests">
              <h4>At the current moment I am exploring the programming language Rust. It is a very interesting language as it provides a lot of modern and inovative coding techniques that simply C++ wouldn't have.</h4>
            </div>
            <div class="Hobbies">
              <h4>When I'm not coding, I like to play the piano, read, and physical activites such as hiking.</h4>
            </div>
          </div>
          <div class="Linked-In">
            <a href='https://www.linkedin.com/in/roman-badiere/' target='_blank' rel='noopener noreferrer'>Let's connect on Linked-In!!!</a>
          </div>
        </div>
    )
}

export default About;
