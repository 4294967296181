import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import Contact from './Contact';
import Home from './Home';
import Nav from './Nav';
import About from './About';
import Portfolio from './Portfolio';
import './App.css';

function App() {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/about' element={<About />} />
        <Route path='/portfolio' element={<Portfolio />} />
      </Routes>
    </Router>
  );
}

export default App;
