import React from 'react';
import Me from './Me.jpg';

const Header = () => {
  return (
    <header>
      <nav class="navbar">
      {/* <div className='logo'>
        <img src={Me} className="Me" alt="Me"></img>
      </div> */}
      <ul>
        <li><a href="/">Home</a></li>
        <li><a href="/about">About</a></li>
        <li><a href="/portfolio">Portfolio</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>
      </nav>
    </header>
  );
}

export default Header;
